<template>
    <div class="box-card" style="text-align: left;">
      <!-- 主要内容 -->
      <el-form :model="detailsForm" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <div class="title" style="margin-top: 0;">基本信息</div>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="17">
            <el-form-item label="姓名：">{{detailsForm.visitor_name}}</el-form-item>
            <el-form-item label="性别：">{{detailsForm.sex === 1 ? '女': '男'}}</el-form-item>
            <el-form-item label="手机号：">{{detailsForm.phone}}</el-form-item>
            <el-form-item label="身份证：">{{detailsForm.card_num}}</el-form-item>
            <el-form-item label="来访日期：">{{$moment(detailsForm.visitor_date).format("YYYY-MM-DD")}}</el-form-item>
            <el-form-item label="来访事由：">{{detailsForm.remark}}</el-form-item>
          </el-col>
          <el-col :span="7" class="img_list_box">
            <div class="personPic" v-for="(item,index) in previewImgList" :key="index" >
              <el-image  :src="item" :preview-src-list="previewImgList" fit="cover" :z-index="9000">
            </el-image>
            <div class="maskBox">访客图像</div>
            </div>
          </el-col>
        </el-row>
        <template v-if="detailsForm.audit_status != 4">
          <div class="title" style="margin-top: 0;">接待人</div>
          <el-divider></el-divider>
          <el-form-item label="组织：">{{detailsForm.organize_name}}</el-form-item>
          <el-form-item label="姓名：">{{detailsForm.person_name}}（{{filterPhoneNumber(detailsForm.person_phone)}}）</el-form-item>
        </template>

        <div class="title" style="margin-top: 0;">审核状态</div>
        <el-divider></el-divider>
        <!-- 1：待审核 2：已通过 3：已拒绝 4:无需审核 -->
        <el-form-item label="申请时间：">{{$moment(detailsForm.create_time).format("YYYY-MM-DD HH:mm:ss")}}</el-form-item>
        <el-form-item label="审核时间：" v-if="detailsForm.audit_status != 1">{{$moment(detailsForm.check_time).format("YYYY-MM-DD HH:mm:ss")}}</el-form-item>
        <el-form-item label="状态：" v-if="detailsForm.audit_status == 1 && $moment(detailsForm.visitor_date).diff( $moment(),'days') < 0">已过期</el-form-item>
        <el-form-item label="状态：" v-else>{{getWayName("VisitorAuditStatus", detailsForm.audit_status)}} </el-form-item>
        <el-form-item label="拒绝原因：" v-if="detailsForm.audit_status == 3">{{detailsForm.refuse_reason}}</el-form-item>
        <el-form-item label="授权设备：" v-if="detailsForm.audit_status == 2">
          <template v-if="detailsForm.permission_detail.length > 0">
            <div v-for="(item, index) in detailsForm.permission_detail" :key="index">
              {{`${item.device_face_name} （${$moment(item.permission_begintime).format("YYYY-MM-DD HH:mm:ss")} ~ ${ $moment(item.permission_endtime).format("HH:mm:ss")}）`}}
            </div>
          </template>
          <span v-else>无</span>
        </el-form-item>
      </el-form>
    </div>
  </template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getVisitorAuditDetail } from '@/api/administrative'
export default {
  props: ['id'],
  data () {
    return {
      detailsForm: {},
      previewImgList: []
    }
  },
  created () {
    this.fnGetVisitorAuditDetail()
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取详情
    async fnGetVisitorAuditDetail () {
      const res = await getVisitorAuditDetail({
        _id: this.id
        // is_edit: true,
      })
      console.log(res)
      this.detailsForm = res && res.Code === 200 ? res.Data : {}
      this.previewImgList = [this.detailsForm.health_code, this.detailsForm.tour_code, this.detailsForm.register_pic].filter(item => {
        return item
      })
    },
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>
  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-top: 50px;
  }
  .demo-ruleForm {
    padding-right: 10px;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  .img_list_box {
    display: flex;
    justify-content: flex-end;
    .personPic{
      margin-right: 5px;
    }
    .maskBox {
    width: 200px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    margin-top: -7px;
  }
    .el-image {
      width: 200px;
      height: 200px;
      // margin-left: 10px;
    }
  }
  </style>
