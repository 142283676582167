<template>
  <!-- 访客管理 --- 访客审核 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left;">
      <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
        <!-- 查询表单 -->
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-row>
            <el-col :span="24">
              <el-form-item label="组织：">
                <el-select popper-class="my-select" filterable v-model="queryForm.organize_id" placeholder="请选择组织"
                  clearable @change="onSubmit">
                  <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
                    :value="item._id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名：">
                <el-input maxlength="15" v-model="queryForm.visitor_name" @keyup.enter.native="onSubmit()"
                  placeholder="请输入姓名" @input="(e) => (queryForm.visitor_name = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="手机号：">
                <el-input maxlength="11" v-model="queryForm.phone" @keyup.enter.native="onSubmit()" placeholder="请输入手机号"
                  @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="身份证：">
                <el-input maxlength="18" v-model="queryForm.card_num" @keyup.enter.native="onSubmit()"
                  placeholder="请输入身份证" @input="(e) => (queryForm.card_num = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="来源：">
            <el-select popper-class="my-select" filterable clearable v-model="queryForm.source_type" placeholder="请选择核验类型" @clear="queryForm.source_type = null" @change="onSubmit()">
              <el-option v-for="item in getOptions('VisitorSourceType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
              <el-form-item v-if="!isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isMultiRow">
            <el-col :span="24">
              <el-form-item label="申请时间：" >
                <el-date-picker size="small" v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                  start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-if="isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </lebo-query>
      <le-card title="访客审核">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="register_pic">
              <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]">
              </el-image>
            </template>
            <template slot-scope="{row}" slot="sex">
              {{ row.sex === 1 ? '女' : '男' }}
            </template>
            <template slot-scope="{row}" slot="visitor_date">
              {{ $moment(row.visitor_date).format("YYYY-MM-DD") }}
            </template>
            <template slot-scope="{row}" slot="audit_status">
              <span v-if="row.audit_status == 1 && $moment(row.visitor_date).diff($moment(), 'days') < 0">已过期</span>
              <span v-else>{{ getWayName("VisitorAuditStatus", row.audit_status) }} </span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookVisitorCheck(row._id)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe>
            <el-table-column label="图像">
              <template slot-scope="scope">
                <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="visitor_name" label="姓名"></el-table-column>
            <el-table-column prop="" label="性别" >
              <template slot-scope="scope">
                {{ scope.row.sex === 1 ? '女' : '男' }}
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="card_num" label="身份证"  show-overflow-tooltip></el-table-column>
            <el-table-column prop="organize_name" label="组织" show-overflow-tooltip></el-table-column>
            <el-table-column prop="visitor_date" label="来访日期">
              <template slot-scope="scope">
                {{ $moment(scope.row.visitor_date).format("YYYY-MM-DD") }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="来访事由" show-overflow-tooltip></el-table-column>
            <el-table-column prop="person_name" label="拜访人员" show-overflow-tooltip></el-table-column>
            <el-table-column prop="audit_status" label="状态" width="100">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.audit_status == 1 && $moment(scope.row.visitor_date).diff($moment(), 'days') < 0">已过期</span>
                <span v-else>{{ getWayName("VisitorAuditStatus", scope.row.audit_status) }} </span>
              </template>
            </el-table-column>
            <el-table-column label="申请时间"  width="140">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="详情">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookVisitorCheck(scope.row._id)"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 查看来访人员 -->
    <lebo-dialog title="查看来访人员" :isShow="showLookVisitorCheck" width="50%" @close="showLookVisitorCheck = false"
      footerSlot closeOnClickModal>
      <lookVisitorCheck v-if="showLookVisitorCheck" :id="dialogLookVisitorCheckId"></lookVisitorCheck>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getXZVisitorInfoAuditPageList } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'
import lookVisitorCheck from './components/lookVisitorCheck/index.vue'
export default {
  components: { lookVisitorCheck },
  data () {
    return {
      timeduan: null,
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        organize_id: '',
        visitor_name: '',
        card_num: '',
        phone: '',
        start_time: '',
        end_time: '',
        source_type: null // 来源 0：全部   1:平台添加 2:前台扫码 3:访客机 4:APP 5:公众号
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 组织下拉列表
      organizationSeleteList: [],
      isMultiRow: true, // 查询下拉弹窗
      showLookVisitorCheck: false,
      dialogLookVisitorCheckId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '图像',
            prop: 'register_pic',
            slot: true
          }, {
            label: '姓名',
            prop: 'visitor_name'
          }, {
            label: '性别',
            prop: 'sex',
            slot: true
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '身份证',
            prop: 'card_num',
            width: 200
          }, {
            label: '组织',
            prop: 'organize_name',
            width: 350
          }, {
            label: '来访日期',
            prop: 'visitor_date',
            slot: true
          }, {
            label: '来访事由',
            prop: 'remark'
          }, {
            label: '拜访人员',
            prop: 'person_name'
          }, {
            label: '申请时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetOrganizationSeleteList()
    this.fngetXZVisitorInfoAuditPageList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
        this.onSubmit()
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      this.organizationSeleteList = res && res.Code === 200 ? res.Data : []
    },
    // 获取分页列表
    async fngetXZVisitorInfoAuditPageList () {
      const res = await getXZVisitorInfoAuditPageList(this.queryForm)
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetXZVisitorInfoAuditPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetXZVisitorInfoAuditPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetXZVisitorInfoAuditPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fngetXZVisitorInfoAuditPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetXZVisitorInfoAuditPageList()
    // },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    },
    lookVisitorCheck (id) {
      this.dialogLookVisitorCheckId = id
      this.showLookVisitorCheck = true
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 510px;
  }
}

.el-image {
  width: 55px;
  height: 55px;

  /deep/.el-image__error {
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size: 0;
  }
}

.queryBox {
  margin-bottom: 20px;
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 500px !important;
}
</style>
